@import url(https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;600;700&display=swap);
@font-face {
  font-family: "Segoe UI";
  src: local("Segoe UI"), url("/static/media/Segoe UI.45472284.ttf") format("truetype");
}
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: Segoe UI;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Segoe UI";
  src: local("Segoe UI"), url("/static/media/Segoe UI.45472284.ttf") format("truetype");
}

p {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 300;
}

.li-dashboard {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 300;
  font-size: 15px;
}

.thumbs {
  padding: 0;
}

.thumb {
  height: 30 !important;
  width: 30 !important;
}

.thumbs-wrapper {
  margin: 0 !important;
}

.link-under-thumbnail:hover {
  text-decoration: underline;
}

